<template>
  <ClientOnly>
    <LibLocaleVersionOverlay
      v-if="mountOverlay"
      :detected-locale="geoInformationStore.detectedLocale"
      :display-language="geoInformationStore.clientLocale"
    />
  </ClientOnly>
</template>
<script lang="ts" setup>
import { useGeoinformationStore } from '~/stores/geoinformationStore/geoinformationStore';

const { $resolvedLocale, $modals } = useNuxtApp();
const geoInformationStore = useGeoinformationStore();

const mountOverlay = computed(() => {
  // We do not want to show multiple modals for now.
  // LocaleVersionOverlay is for now the least important modal.
  // Multiple modals might be possible via the RegionSelector and a
  // change of the prepared locale triggering this computed.
  // @see https://gcp.baslerweb.com/jira/browse/DBP-117
  // XXX: we might want to use a global hierarchy for modals.
  if ($modals.regionSelectorOpen.value) {
    return;
  }
  if (
    !geoInformationStore.clientLocale ||
    !geoInformationStore.detectedLocale
  ) {
    // can only show overlay if geo information is present
    return false;
  }

  if (
    geoInformationStore.preferredLocale &&
    geoInformationStore.preferredLocale?.toLowerCase() ===
      $resolvedLocale.value?.toLowerCase()
  ) {
    // we do not show the overlay if preferred users locale
    // matches the current locale.
    // @see https://gcp.baslerweb.com/jira/browse/DBP-117
    return false;
  }

  return true;
});
</script>
